// **************************************************
// Input
// **************************************************

@import '../../../scss/import';

.mod-input {
	margin-bottom: 20px;
	width: 100%;
	position: relative;
	display: inline-block;

	// ##################################################

	&__label {
		position: relative;
		display: block;
		margin-bottom: 16px;
	}

	// ##################################################

	&__field {
		position: relative;
		width: 100%;
		outline: none;
		z-index: 1;
		max-width: 282px;
		padding: 8px 16px;
		height: 40px;
		font-weight: 100;
		color: $cgp_white;
		border-radius: 16px;
		border: 1px solid $cgp_white;
		background-color: rgba(2, 50, 92, 0.47);
		transition: border-color 0.3s ease;

		// ###########################################################

		&::placeholder {
			color: $cgp_white;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: $cgp_white;
			opacity: 1;
		}

		&::-ms-input-placeholder {
			color: $cgp_white;
			opacity: 1;
		}

		&:focus {
			border-color: $cgp_blue;
		}

		// ###########################################################

		// stylelint-disable-next-line
		.#{$class_validation_failed} &,
		&.#{$class_validation_failed} {
			color: $cgp_validation_failed;
			border-color: $cgp_validation_failed;
		}
	}

	// ##################################################

	textarea {
		resize: none;
		height: 314px;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: 260px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 200px;
		}
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_validation_failed} {
		margin-bottom: 38px;
		color: $cgp_validation_failed;
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_disabled} {
		// stylelint-disable-next-line
		.mod-input__field {
			pointer-events: none;
			opacity: 0.5;
			background-color: rgba(0, 0, 0, 0.05);
		}
	}
}

// ###########################################################

.validation-message {
	display: none;
	color: $cgp-validation-failed;
	position: relative;
	padding: 0 15px;
	font-size: 12px;
	margin-top: 5px;
	text-align: right;

	// stylelint-disable-next-line
	.#{$class_validation_failed} & {
		display: block;
	}
}
