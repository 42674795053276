// **************************************************
// Timer
// **************************************************

@import '../../../scss/import';

.timer {
	position: relative;
	display: flex;
	justify-content: center;
	grid-template-columns: repeat(3, minmax(0, 1fr));

	&__item {
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		margin: 0 28px;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			margin: 0 15px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin: 0 8px;
		}
	}

	// ##################################################

	&__count {
		width: 186px;
		height: 186px;
		border: 2px solid $cgp_white;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 125px;
		font-weight: 700;
		text-align: center;
		padding-top: 10px;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			width: 140px;
			height: 140px;
			font-size: 80px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			width: 80px;
			height: 80px;
			font-size: 42px;
			padding-top: 5px;
		}
	}

	// ##################################################

	&__label {
		margin-top: 44px;
		font-size: 40px;
		font-weight: 700;
		text-transform: uppercase;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			margin-top: 20px;
			font-size: 32px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 12px;
			font-size: 22px;
		}
	}
}
