// **************************************************
// Welcome Screen
// **************************************************

@import '../../../scss/import';

.welcome-screen {
	text-align: center;
	padding: 164px 20px 40px;
	min-height: 100vh;

	// Smaller Screen
	@media (max-height: 700px) {
		padding: 16vh 20px 2vh;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		min-height: 1px;
		padding: 110px 20px 30px;
	}

	// ##################################################

	&__title {
		margin-bottom: 50px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 30px;
		}
	}

	// ##################################################

	&__header {
		margin-bottom: 38px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 30px;
		}

		// ##################################################

		&-super-title {
			margin-bottom: 8px;
		}
	}

	// ##################################################

	&__form {
		max-width: 345px;
	}

	// ##################################################

	&__checkboxes {
		text-align: left;
		margin-top: 64px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 40px;
		}

		// ##################################################

		.checkbox {
			& + .checkbox {
				margin-top: 22px;
			}
		}
	}
}
