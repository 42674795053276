//*******************************************************************************
// 1.0 - WEBFONTS : Nunito Sans
//*******************************************************************************

@font-face {
	font-family: 'Winston';
	src: url('../fonts/Winston-Thin.woff2') format('woff2'), url('../fonts/Winston-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Winston';
	src: url('../fonts/Winston-Medium.woff2') format('woff2'), url('../fonts/Winston-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Winston';
	src: url('../fonts/Winston-Bold.woff2') format('woff2'), url('../fonts/Winston-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
