//*******************************************************************************
// PLACEHOLDERS
//*******************************************************************************

// General extensions
%content {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	pointer-events: none;
}

// ##################################################

%reset_button {
	background: transparent;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	padding: 0;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
}

// ##################################################

%abs_pos_fullwidth {
	height: 100%;
	left: 0;
	position: absolute;
	width: 100%;
	top: 0;
	pointer-events: none;
}

// ##################################################

%flex_center_center {
	display: flex;
	align-items: center;
	justify-content: center;
}

// ##################################################

%sr_only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	white-space: nowrap;
	border: 0;
	visibility: inherit;
	clip: rect(0, 0, 0, 0);
}

// ##################################################
// FONT SIZES
// ##################################################

// General page text - Paragraph
%page_text {
	font-size: 18px;
	font-weight: 500;
	line-height: normal;

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		font-size: 16px;
	}
}

// ##################################################

%title {
	font-weight: 700;
	text-shadow: -4px 3px 0 #112645;
}

// ##################################################

%title_h1 {
	@extend %title;
	font-size: 72px;
	line-height: normal;
	text-transform: uppercase;

	// Tablet L and down
	@media #{$breakpoint-md-down} {
		font-size: 60px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 36px;
	}
}

// ##################################################

%title_h2 {
	@extend %title;
	font-size: 57px;

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		font-size: 50px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 36px;
	}
}

// ##################################################

%title_h3 {
	@extend %title;
	font-size: 26px;
	line-height: normal;
	text-shadow: none;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 22px;
	}
}

// ##################################################

%title_h4 {
	@extend %title;
	font-size: 20px;
	line-height: 1.44;
}

// ##################################################

%title_h5 {
	@extend %title;
	font-size: 18px;
	line-height: 1.44;
}

// ##################################################

%title_h6 {
	@extend %title;
	font-size: 16px;
	line-height: 1.44;
}

// ##################################################

%fontsize78 {
	@extend %title;
	font-size: 78px;

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		font-size: 50px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 36px;
	}
}

// ##################################################

%fontsize54 {
	@extend %title;
	font-size: 54px;
	letter-spacing: 0.54px;
	line-height: 1;
	color: $cgp_blue;
	font-weight: 800;

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		font-size: 40px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 36px;
	}
}

// ##################################################

%fontsize37 {
	font-size: 37px;
	font-weight: 100;

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		font-size: 30px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 24px;
	}
}

// ##################################################

%fontsize33 {
	font-size: 33px;
	font-weight: 700;

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		font-size: 30px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 24px;
	}
}

// ##################################################

%fontsize24 {
	font-size: 24px;
	font-weight: 700;
	text-transform: uppercase;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 18px;
	}
}

// ##################################################

%fontsize20 {
	font-weight: 500;
	font-size: 20px;
	line-height: 1.44;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 18px;
	}
}

// ##################################################

%fontsize18 {
	@extend %page_text;
}

// ##################################################

%fontsize16 {
	font-size: 16px;
	line-height: 1.5;
}

// ##################################################

%fontsize15 {
	font-size: 15px;
	letter-spacing: 0.15px;
	line-height: 1;
	font-weight: 800;
}

// ##################################################

%fontsize14 {
	font-size: 14px;
	line-height: 1.29;
}

// ##################################################

%inherit_textstyles {
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	text-transform: inherit;
	text-align: inherit;
	font-style: inherit;
	font-family: inherit;
	background-color: transparent;
}

// ##################################################

%backface {
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
}

// ##################################################

%focus_visible {
	outline-offset: 0;
	outline: 2.5px solid $cgp_black;
}

// ##################################################

%bg_video {
	position: absolute;
	min-width: 100%;
	min-height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: $cgp_black;
}

// ##################################################

%hover_lines {
	// ##################################################
	// Bottom line

	// stylelint-disable-next-line
	&::before,
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		width: 0;
		right: 0;
		height: 2px;
		background-color: $cgp_white;
		pointer-events: none;
	}

	// ##################################################

	// stylelint-disable-next-line
	&::before {
		transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
	}

	// ##################################################

	// stylelint-disable-next-line
	&::after {
		transition: width 0.2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
	}
}
