// **************************************************
// Site Header
// **************************************************

@import '../../../scss/import';

.header-main {
	position: absolute;
	right: 75px;
	top: 66px;
	z-index: $zindex_header;

	// Small Desktop and down
	@media #{$breakpoint-lg-down} {
		right: 30px;
		top: 56px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		top: inherit;
		bottom: 0;
		padding: 30px 15px;
		display: none;
	}
}
