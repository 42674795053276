// **************************************************
// Checkbox
// **************************************************

@import '../../../scss/import';

.checkbox {
	user-select: none;
	font-size: 15px;
	font-weight: 500;
	line-height: 1.07;
	text-align: left;
	max-width: 800px;

	// ##################################################

	&__label {
		position: relative;
		padding-left: 28px;
		cursor: pointer;
		margin: 0;
		display: block;

		// ##################################################

		&::before,
		&::after {
			content: '';
			width: 14px;
			height: 14px;
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 100%;
		}

		// ##################################################

		// Square
		&:before {
			// Style for "Rectangle"
			width: 14px;
			height: 14px;
			border-radius: 20px;
			border: 1px solid $cgp_white;
			background-color: rgba(2, 50, 92, 0.47);
		}

		// ##################################################

		// Check mark
		&::after {
			transform: scale(0);
			background-color: $cgp_white;
			transition: transform 0.3s ease;
		}
	}

	// ##################################################

	input {
		// ##################################################

		&:checked ~ label {
			// ##################################################

			&::after {
				transform: scale(1);
			}
		}
	}

	// ##################################################

	a {
		text-decoration: underline;
	}

	// ##################################################
	// Without label

	&.hide-label {
		width: 18px;
		height: 18px;

		// ##################################################

		.checkbox__label {
			&::before,
			&::after {
				top: 0;
			}
		}
	}

	// stylelint-disable-next-line
	&.#{$class_validation_failed} {
		// stylelint-disable-next-line
		.checkbox__label {
			color: $cgp_validation_failed;

			// stylelint-disable-next-line
			&::before {
				border-color: $cgp_validation_failed;
			}
		}
	}
}
