// ##################################################
// BACKGROUNDS
// ##################################################

.cover-bg {
	@extend %abs_pos_fullwidth;
	background-size: cover;
	background-position: center center;

	img {
		display: none;
	}
}

.covered {
	@extend %abs_pos_fullwidth;
	max-height: initial;
}

// ##################################################
// TEXT ALIGN
// ##################################################

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

// ##################################################
// SCREEN READER
// ##################################################

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.button {
	@extend %reset_button;
}

// ##################################################

.p-0 {
	padding: 0;
}

// ##################################################

.mx-auto {
	width: 100%;
	display: block;
	margin: 0 auto;
}

// ##################################################

.f-auto {
	flex: auto;
	display: flex;
	flex-direction: column;
}

// ##################################################
// TEXT
// ##################################################

.text {
	// ##################################################

	&--78 {
		@extend %fontsize78;
	}

	// ##################################################

	&--37 {
		@extend %fontsize37;
	}

	// ##################################################

	&--33 {
		@extend %fontsize33;
	}

	// ##################################################

	&--24 {
		@extend %fontsize24;
	}

	// ##################################################

	&--20 {
		@extend %fontsize20;
	}

	// ##################################################

	&--14 {
		@extend %fontsize14;
	}

	// ##################################################

	&--bd {
		font-weight: 700;
	}

	// ##################################################

	&--md {
		font-weight: 500;
	}

	// ##################################################

	&--uppercase {
		text-transform: uppercase;
	}
}

// ##################################################
// VISIBILITY
// ##################################################

.hide {
	display: none;
}

.show {
	display: block;
}

.d-flex {
	display: flex;
}

.d-flex-cc,
.d-flex-center-center {
	@extend %flex_center_center;
}

// ##################################################

.section-logo {
	position: absolute;
	left: 76px;
	top: 50px;
	max-width: 124px;
	width: 100%;

	// Small Desktop and down
	@media #{$breakpoint-lg-down} {
		left: 30px;
		top: 20px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		top: 12px;
		left: 20px;
		max-width: 90px;
	}

	// ##################################################

	img {
		width: 100%;
		height: auto;
	}
}
