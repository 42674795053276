// **************************************************
// Intro Screen
// **************************************************

@import '../../../scss/import';

.countdown-screen {
	text-align: center;
	padding: 16vh 20px;
	height: 100vh;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;

	// Smaller Screen
	@media (max-height: 700px) {
		padding: 16vh 20px 2vh;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		height: auto;
		padding: 70px 20px 10px;
	}

	// ##################################################

	&__logo {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		top: 6.5vh;

		// Mobile
		@media #{$breakpoint-xs-only} {
			top: 15px;
		}
	}

	// ##################################################

	&__title {
		margin-bottom: 90px;

		// Smaller Screen
		@media (max-height: 700px) {
			margin-bottom: 30px;
		}

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			margin-bottom: 30px;
		}
	}
}
