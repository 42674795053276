// **************************************************
// Button
// **************************************************

@import '../../../scss/import';

// Style
.btn {
	position: relative;
	cursor: pointer;
	font-size: 20px;
	font-weight: 700;
	line-height: 1.5;
	padding: 2px 25px;
	min-width: 146px;
	min-height: 40px;
	box-shadow: -4px 3px 0 $cgp_blue;
	border-radius: 16px;
	background-color: $cgp_white;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: $cgp_blue;
	text-transform: uppercase;
	text-align: center;
	transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;

	// ##################################################

	i,
	span {
		position: relative;
		z-index: 4;
	}

	// ##################################################
	// Hover/Focus

	&:hover,
	&:focus {
		box-shadow: 0 0 0 3px rgba($cgp_black, 0.2);
	}

	// ##################################################

	&:focus-visible {
		outline-color: transparent;
	}

	// ##################################################

	&--blue {
		color: $cgp_white;
		box-shadow: -4px 3px 0 #02203c;
		background-color: $cgp_blue;

		// ##################################################
		// Hover/Focus

		&:hover,
		&:focus {
			box-shadow: 0 0 0 3px rgba(#02203c, 0.2);
		}
	}

	// ##################################################

	// stylelint-disable-next-line
	&.#{$class_disabled} {
		border: 1px solid rgba($cgp_black, 0.2);
		background-color: rgba($cgp_black, 0.2);
		pointer-events: none;
	}
}
