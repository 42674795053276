// **************************************************
// Score Screen
// **************************************************

@import '../../../scss/import';

.score-screen {
	text-align: center;
	padding: 140px 20px 80px;
	min-height: 100vh;

	// Smaller Screen
	@media (max-height: 700px) {
		padding: 16vh 20px 2vh;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		min-height: 1px;
		padding: 110px 20px 50px;
	}

	// ##################################################

	&__header {
		margin-bottom: 46px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 30px;
		}

		// ##################################################

		.title {
			font-weight: 100;

			// ##################################################

			strong {
				font-weight: 700;
			}
		}
	}

	// ##################################################

	&__table {
		max-width: 420px;
		min-height: 100px;
		border-radius: 42px;
		background-color: rgba(2, 50, 92, 0.47);
		padding: 50px 60px;
		margin-bottom: 64px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 40px;
		}

		// ##################################################

		&-header {
			display: flex;
			align-items: flex-end;
			margin-bottom: 12px;
		}

		// ##################################################

		&-line {
			flex: auto;
			height: 2px;
			margin-bottom: 5px;
			background-color: $cgp_white;
		}

		// ##################################################

		&-body {
			display: grid;
			grid-gap: 12px;
		}

		// ##################################################

		&-row {
			display: flex;
			justify-content: space-between;

			// stylelint-disable-next-line
			&.#{$class_active} {
				color: $cgp_sky_blue;
			}
		}
	}

	// ##################################################

	&__footer {
		&-buttons {
			max-width: 420px;
			display: grid;
			grid-gap: 88px;
			margin-top: 38px;
			grid-template-columns: repeat(2, 1fr);

			// Mobile
			@media #{$breakpoint-xs-only} {
				grid-gap: 40px;
			}
		}
	}
}
