// **************************************************
// Game Screen
// **************************************************

@import '../../../scss/import';

.game-screen {
	text-align: center;
	padding: 144px 20px 40px;
	min-height: 100vh;

	// Mobile
	@media #{$breakpoint-xs-only} {
		min-height: 1px;
		padding: 110px 20px 30px;
	}

	// ##################################################

	&__left {
		position: absolute;
		top: 60px;
		left: 80px;
		height: calc(100% - 26vh);

		// Small desktop and down
		@media #{$breakpoint-lg-down} {
			left: 4vw;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			top: 4vh;
		}
	}

	// ##################################################

	&__score {
		position: absolute;
		top: 11.5vh;
		left: 50%;
		transform: translate(-50%, 0);

		// Mobile
		@media #{$breakpoint-xs-only} {
			top: 4vh;
		}

		// ##################################################

		&-title {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 3.1vh;
		}

		// ##################################################

		&-count {
			color: $cgp_sky_blue;
			font-size: 7vh;
			font-weight: 700;
		}
	}

	// ##################################################

	&__title {
		@extend %title;
		position: absolute;
		left: 0;
		top: 27vh;
		width: 100%;
		font-size: 12vh;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			top: 25vh;
			font-size: 10vh;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			top: 18vh;
			font-size: 8vh;
			padding: 0 18vw;
		}
	}

	// ##################################################

	&__image {
		// ##################################################

		&-left,
		&-right {
			position: absolute;
			top: 22vh;
			pointer-events: none;
			max-width: 25vw;
			width: 100%;

			// Small desktop and down
			@media #{$breakpoint-lg-down} {
				top: 35vh;
				max-width: 25vw;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				display: none;
			}
		}

		// ##################################################

		&-left {
			left: 10vw;
		}

		// ##################################################

		&-right {
			right: 10vw;
		}

		// ##################################################

		img {
			max-height: 33vh;
			width: 100%;
			object-fit: contain;

			// Small desktop and down
			@media #{$breakpoint-lg-down} {
				max-height: 18vh;
			}
		}
	}
}

// **************************************************
// Progress bar
// **************************************************

.progress-bar {
	width: 1.2vh;
	height: 100%;
	position: absolute;
	left: 0;
	border-radius: 50px;
	overflow: hidden;
	background-color: rgba(2, 50, 92, 0.47);

	// ##################################################
	// Border

	&::before {
		@extend %content;
		border-radius: 50px;
		border: 1px solid $cgp_white;
	}

	// ##################################################

	&__inner {
		@extend %abs_pos_fullwidth;
		background-color: $cgp_white;
		border-radius: 50px;
		transition: height 0.3s ease;
	}

	// ##################################################

	&__label {
		position: absolute;
		left: 1.2vh;
		top: 0;
		writing-mode: tb-rl;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 3.1vh;
		padding-left: 10px;
	}
}
