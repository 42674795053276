// **************************************************
// Thankyou Screen
// **************************************************

@import '../../../scss/import';

.thankyou-screen {
	text-align: center;
	padding: 140px 20px 80px;
	min-height: 100vh;
	justify-content: center;
	align-items: center;

	// Smaller Screen
	@media (max-height: 700px) {
		padding: 16vh 20px 2vh;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		min-height: 1px;
		padding: 110px 20px 50px;
	}

	// ##################################################

	&__text,
	&__title {
		margin-bottom: 60px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 30px;
		}
	}

	// ##################################################

	&__title {
		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 20px;
		}

		// ##################################################

		.title {
			font-weight: 100;

			// ##################################################

			strong {
				font-weight: 700;
			}
		}
	}

	// ##################################################

	&__button {
		.btn {
			min-width: 206px;
		}
	}
}
