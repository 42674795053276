// **************************************************
// Menu
// **************************************************

@import '../../../scss/import';

.menu {
	// ##################################################

	li {
		display: inline-block;
		margin-left: 44px;
	}

	// ##################################################

	&__link {
		font-size: 15px;
		font-weight: 700;
		line-height: normal;
		display: block;
		position: relative;
		color: $cgp_white;
		text-transform: uppercase;
		@extend %hover_lines;

		// stylelint-disable-next-line
		&:hover {
			&::before,
			&::after {
				width: 100%;
				left: 0;
			}
		}
	}
}
