// **************************************************
// Info Screen
// **************************************************

@import '../../../scss/import';

.info-screen {
	text-align: center;
	padding: 144px 20px 40px;
	min-height: 100vh;

	// Smaller Screen
	@media (max-height: 700px) {
		padding: 16vh 20px 2vh;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		min-height: 1px;
		padding: 110px 20px 30px;
	}

	// ##################################################

	&__header {
		margin-bottom: 62px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 50px;
		}

		// ##################################################

		&-super-title {
			margin-bottom: 8px;
		}

		// ##################################################

		&-title {
			margin-bottom: 14px;
		}
	}

	// ##################################################

	&__list {
		max-width: 678px;
		display: grid;
		grid-gap: 90px;

		// Tablet P and up
		@media #{$breakpoint-sm-up} {
			grid-auto-flow: column;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 240px;
			grid-gap: 40px;
			line-height: 1.4;
		}

		.icon {
			// Mobile
			@media #{$breakpoint-xs-only} {
				width: 120px;
				height: 120px;
			}
		}
	}

	// ##################################################

	&__item {
		position: relative;

		// ##################################################

		.title {
			text-shadow: none;
			margin-bottom: 16px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-bottom: 8px;
			}
		}

		// ##################################################

		.icon {
			opacity: 0.5;
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: -1;
			transform: translate(-50%, -50%);

			// ##################################################

			&::before {
				background-color: $cgp_blue;
			}
		}
	}

	// ##################################################

	&__form {
		max-width: 400px;
		margin-top: 82px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 60px;
		}

		// ##################################################

		&-title {
			text-transform: uppercase;
			margin-bottom: 12px;
		}

		// ##################################################

		.mod-input {
			margin-bottom: 28px;

			// ##################################################

			&__label {
				margin-bottom: 26px;
			}
		}
	}
}
