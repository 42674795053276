// **************************************************
// Modal
// **************************************************

@import '../../../scss/import';

.modal {
	position: fixed;
	left: 0;
	height: 100%;
	top: 0;
	width: 100%;
	z-index: $zindex_modal;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	padding: 30px;
	visibility: hidden;
	background-color: rgba($cgp_black, 0.6);
	transition: opacity 0.3s ease, visibility 0.3s ease;

	// Mobile xs
	@media #{$breakpoint-xs-only} {
		padding: 20px;
	}

	// stylelint-disable-next-line
	&.#{$class_open_modal} {
		visibility: visible;
		opacity: 1;
	}

	// ##################################################

	&__close {
		position: absolute;
		top: 20px;
		right: 20px;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		background-color: $cgp_blue;
		z-index: 9;

		// Mobile
		@media #{$breakpoint-xs-only} {
			top: 22px;
			right: 16px;
		}
	}

	// ##################################################

	&__inner {
		width: 100%;
		max-width: 500px;
		background-color: $cgp_white;
		border-radius: 32px;
		position: relative;
		margin: auto;
		color: $cgp_blue;
		height: 65vh;
		overflow: hidden;
	}

	// ##################################################

	&__body {
		height: 100%;
		left: 0;
		position: absolute;
		width: 100%;
		top: 0;
		overflow: auto;
		padding: 50px 50px 40px;
		@include scrollbar(6px, rgba($cgp_blue, 0.5), rgba($cgp_blue, 1), rgba($cgp_blue, 0.5), 0);

		// Mobile
		@media #{$breakpoint-xs-only} {
			border-radius: 10px;
			padding: 50px 30px 30px;
		}

		// Mobile xs
		@media (max-width: 360px) {
			padding: 50px 20px 20px;
		}
	}

	// ##################################################

	.editor-text {
		line-height: 1.5;

		h1,
		h2,
		h3 {
			@extend %title_h2;
			text-shadow: 0 2px 0 rgba(0, 66, 136, 0.26);
			font-size: 24px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 16px;
			}

			// Mobile xs
			@media (max-width: 360px) {
				font-size: 14px;
			}
		}
	}
}
